<template>
	<TheHeader hero="/images/screenshots/benefits-for-employees.png" :is-shadow="true">
		<template v-slot:title>
			ESOP as an Excellent Way to Accelerate Your Career. <span class="line relative inline-block text-theme">Proven.</span>
		</template>
		<template v-slot:description>
			Participate on a company's success and be part of a meaningful adventure.
		</template>
	</TheHeader>

	<div>
		<section class="page-container md:flex md:items-start md:space-x-12 py-20 md:space-y-0 space-y-16">
			<div class="xl:w-5/12 md:w-6/12">
				<div data-aos="fade-up">
					<small class="block mb-8 uppercase text-theme font-extrabold">
						Benefits
					</small>
					<h3 class="2xl:text-4xl text-3xl font-black leading-normal mb-4">
						Financial Benefit
					</h3>
					<p class="mb-5 text-secondary leading-7 font-semibold">
						An ESOP simply gives the right for you to buy share in the company at a predetermined price in the future. If your company is able to grow and be successful, then your ESOP can become very valuable for you.
					</p>
					<p class="mb-11 text-secondary leading-7 font-semibold">
						It puts the participants in an ownership-like position and allows employees to obtain an economic interest in the pursuit of growth, development, profitability and financial success of the company.
					</p>
				</div>

				<div data-aos="fade-up">
					<h3 class="2xl:text-4xl text-3xl font-black leading-normal mb-4">
						Motivation
					</h3>
					<p class="mb-11 leading-7 text-secondary font-semibold">
						You are always going to be more motivated and harder working if you are to receive a proportion of the fruits of your labour, not just a fixed salary. If you are working in an environment that is motivated and enthusiastic, there are bigger chances of seeing positivity and changes.
					</p>
				</div>

				<div data-aos="fade-up" class="mb-12">
					<h3 class="2xl:text-4xl text-3xl font-black leading-normal mb-4">
						Team Spirit
					</h3>
					<p class="leading-7 text-secondary font-semibold">
						ESOPs evoke a sense of unity when the team comes out and deals with the problem themselves. You will feel that you are working for something bigger than yourself, something which you could not have done alone.
					</p>
				</div>

				<EmployeeInvitationOnly />
			</div>

			<kinesis-container event="scroll" tag="div" class="text-center xl:w-7/12 md:w-6/12 sticky top-24">
				<kinesis-element
					tag="img"
					src="/images/benefits-for-employees.svg"
					:strength="60"
					type="translate"
					class="inline-block md:max-w-md md:w-full sm:w-96 w-72"
				/>
			</kinesis-container>
		</section>
	</div>
</template>
<script>

	import TheHeader from "../components/TheHeader";
	import EmployeeInvitationOnly from "../components/EmployeeInvitationOnly";

	export default {
		name: 'BenefitsForEmployees',
		components: {
			EmployeeInvitationOnly,
			TheHeader,
		}
	}
</script>